<i18n>
{
  "en": {
		"editYourDetails": "Edit your details"
	},
  "fi": {
		"editYourDetails": "Muokkaa tietojasi"
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					:src="config._hero_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.membership.length"
						class="white--text"
					>
						<ul class="no-list">
							<li
								v-for="item in user.membership"
								:key="item.id"
							>
								{{ item._list_title }}
							</li>
						</ul>
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.membership.length"
						class="white--text"
					>
						<ul class="no-list">
							<li
								v-for="item in user.membership"
								:key="item.id"
							>
								{{ item._list_title }}
							</li>
						</ul>
					</v-card-subtitle>
				</template>
				<v-card-text v-if="user._details && user._details.length">
					<FilterableList
						:items="user._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
					/>
				</v-card-text>

				<template v-if="$router.options.routes.some(item => item.name === 'myAccount')">
					<v-divider />
					<v-card-actions>
						<v-btn
							:to="{ name: 'myAccount' }"
							color="primary"
							text
						>
							<v-icon left>
								mdi-pencil
							</v-icon>
							{{ $i18n.t('editYourDetails') }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
			<a
				v-if="config.ads.membership_card.length && config.ads.membership_card[0].image"
				:href="config.ads.membership_card[0].link"
				class="d-block mt-4"
				rel="no-referrer noopener"
				target="_blank"
			>
				<v-img
					:src="config.ads.membership_card[0].image.url"
					:alt="config.ads.membership_card[0].image.description"
				/>
			</a>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'config',
			'user',
			'benefits',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
