<i18n>
{
  "en": {
		"noItemsText": "No benefits to show."
	},
  "fi": {
		"noItemsText": "Jäsenetuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view :key="$route.fullPath" />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'benefitContainer'">
			<v-container class="container--narrow">
				<FilterableList
					:items="items"
					:no-items-text="$i18n.t('noItemsText')"
					@itemClick="item => $router.push({ name: 'benefit', params: { pagename: item.name } })"
				/>
				<div
					v-if="config.sponsors.length"
					class="mt-8"
				>
					<v-row>
						<v-col
							v-for="sponsor in config.sponsors"
							:key="sponsor.id"
							cols="12"
							md="6"
						>
							<v-card
								:href="sponsor.link"
								rel="no-referrer noopener"
								target="_blank"
								flat
							>
								<v-img
									:src="sponsor.logo.url"
									:alt="sponsor.logo.description"
								/>
								<v-card-title>
									{{ sponsor.title }}
								</v-card-title>
								<v-card-subtitle>
									{{ sponsor.link }}
								</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',
	computed: {
		...mapState({
			config: state => state.config,
			items: state => state.benefits,
		}),
	},
	mounted () {
		this.$api.Benefits.doRequest()
	},
}
</script>
