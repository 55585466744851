<i18n>
{
  "en": {
		"noItemsText": "No pages to show."
	},
  "fi": {
		"noItemsText": "Sivuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view :key="$route.fullPath" />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'pageContainer'">
			<v-container class="container--narrow">
				<a
					v-if="config.ads.material_bank.length && config.ads.material_bank[0].image"
					:href="config.ads.material_bank[0].link"
					rel="no-referrer noopener"
					target="_blank"
				>
					<v-img
						:src="config.ads.material_bank[0].image.url"
						:alt="config.ads.material_bank[0].image.description"
					/>
				</a>
				<FilterableList
					:items="items"
					:no-items-text="$i18n.t('noItemsText')"
					@itemClick="item => $router.push({ name: 'page', params: { pagename: item.name } })"
				/>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PageContainer',
	computed: {
		...mapState({
			config: state => state.config,
			items: state => state.pages,
		}),
	},
	mounted () {
		this.$api.Pages.doRequest()
	},
}
</script>
